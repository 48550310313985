import React from 'react';
import { Invoice } from 'bitcoin-invoice-components';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { BITBOX } from 'bitbox-sdk';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  Input,
  InputLabel,
  Checkbox,
  Button,
  H3,
  Tabs,
} from '@bitcoin-portal/bitcoincom-storybook';
import merge from 'lodash/merge';
import {
  InputWrapper,
  OutputWrapper,
  Instructions,
  Content,
  InputError,
  Buttons,
  RetryButtons,
  CustomContentBlock,
  Red,
  Form,
  Feedback,
  DividendDetails,
  ProgressContainer,
  ErrorContainer,
  TextArea,
  Small,
  Label,
  InvoiceTable,
  InvoiceWrapper,
  CustomSelect,
  CustomLink,
  CheckLabel,
} from './styled';

const SLPSDK = require('slp-sdk');

const SLP = new SLPSDK({ restURL: 'https://rest.bch.actorforth.org/v2/' });

const slpList = require('slp-list');

const Big = require('big.js');

const bitbox = new BITBOX({ restURL: 'https://rest.bch.actorforth.org/v2/' });

const inputState = { untouched: 0, valid: 1, invalid: 2 };

class SlpDivWidget extends React.Component {
  constructor(props) {
    super(props);
    this.initialFormData = {
      slpTokenId: {
        value: '',
        state: inputState.untouched,
        error: null,
      },
      dividendBch: {
        value: '',
        state: inputState.untouched,
        error: null,
      },
      opReturn: {
        value: '',
        state: inputState.untouched,
        error: null,
      },
      customBlockheight: {
        value: '',
        state: inputState.untouched,
        error: null,
      },
      excludedAddresses: {
        value: '',
        state: inputState.untouched,
        error: null,
      },
    };
    this.initialTokenInfo = {
      minSlpBalanceForDividend: 0,
      circulatingSupply: 0,
      tokenId: '',
      streetName: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTokenIdChange = this.handleTokenIdChange.bind(this);
    this.handleDividendChange = this.handleDividendChange.bind(this);
    this.handleOpReturnChange = this.handleOpReturnChange.bind(this);
    this.handleExcludedAddressesChange = this.handleExcludedAddressesChange.bind(
      this,
    );
    this.reset = this.reset.bind(this);
    this.handleBackToStart = this.handleBackToStart.bind(this);
    this.throwTokenIdError = this.throwTokenIdError.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.copyUri = this.copyUri.bind(this);
    this.setTabState = this.setTabState.bind(this);
    this.parseInvoicePromises = this.parseInvoicePromises.bind(this);
    this.allInvoicesBuiltCheck = this.allInvoicesBuiltCheck.bind(this);
    this.getTokenHolderAddresses = this.getTokenHolderAddresses.bind(this);
    this.getBestBlockForSlpList = this.getBestBlockForSlpList.bind(this);
    this.validateBlockheight = this.validateBlockheight.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleBlockheightChange = this.handleBlockheightChange.bind(this);
    this.expireInvoices = this.expireInvoices.bind(this);
    this.handleSlpdbChange = this.handleSlpdbChange.bind(this);
    this.toggleAdvancedOptions = this.toggleAdvancedOptions.bind(this);
    this.toggleDividendOutput = this.toggleDividendOutput.bind(this);
    this.handleAddrExclude = this.handleAddrExclude.bind(this);
    this.handleOpReturnMsgInclude = this.handleOpReturnMsgInclude.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.state = {
      formData: merge({}, this.initialFormData),
      tokenInfo: merge({}, this.initialTokenInfo),
      effectiveCirculatingSupply: 0,
      effectiveMinSlpBalanceForDividend: 0,
      eligibleAddresses: 0,
      outputTx: [],
      txBuilt: false,
      loading: false,
      paymentIds: [],
      tab: false,
      limitError: null,
      apiError: null,
      calcError: null,
      slpdbError: null,
      appStatus: '',
      blockUsedInScan: null,
      selectedOption: 'lastConfirmedBlock',
      invoicesExpired: '',
      slpdb: {
        value: 'https://slpdb.bitcoin.com',
        label: 'slpdb.bitcoin.com',
      },
      toggleAdvanced: false,
      toggleOutput: false,
      excludeAddresses: false,
      includeOpReturnMsg: false,
      formStep: 'one',
      windowWidth: 0,
    };

    this.dustLimit = 5000;
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  getTokenHolderAddresses(
    bestBlock,
    tokenInfo,
    minSlpBalanceForDividend,
    dividendBch,
  ) {
    const {
      intl: { formatMessage },
    } = this.props;
    const {
      slpdb,
      excludeAddresses,
      includeOpReturnMsg,
      formData,
    } = this.state;
    const queryLimit = 10000;
    const { tokenId, streetName } = tokenInfo;
    // prev const { tokenId, streetName, circulatingSupply } = tokenInfo; ... circulatingSupply is not needed though, only supply among eligible addresses
    const batchSize = 2500;

    const slpdbUrl = slpdb.value;

    slpList.SlpdbQueries.GetAddressListFor(bestBlock, tokenId, slpdbUrl).then(
      result => {
        /*
        console.log(
          `Result from slpList.SlpdbQueries.GetAddressListFor(${bestBlock}, ${tokenId})`,
        );
        console.log(result);
        console.log(result.size);
        */
        // if you get an empty one back, slpdb is probably down, return slpdb error
        if (result.size === 0) {
          const slpdbError = (
            <FormattedMessage id="slp-dividend-calculator.errors.slpdbErrorTwo" />
          );
          return this.setState({
            loading: false,
            slpdbError,
          });
        }
        // If user is excluding addresses, remove excluded addresses and those too small
        if (excludeAddresses && formData.excludedAddresses.value !== '') {
          // define array of excluded addresses in SLP format
          let excludedSlpAddresses = formData.excludedAddresses.value;
          // Remove spaces
          excludedSlpAddresses = excludedSlpAddresses.replace(/\s+/g, '');
          // If the last character is a comma, remove it
          if (
            excludedSlpAddresses.charAt(excludedSlpAddresses.length - 1) === ','
          ) {
            excludedSlpAddresses = excludedSlpAddresses.slice(0, -1);
          }
          // Parse for comma-separated list here
          excludedSlpAddresses = excludedSlpAddresses.split(',');
          // Iterate and convert to SLP format
          for (let i = 0; i < excludedSlpAddresses.length; i += 1) {
            excludedSlpAddresses[i] = SLP.Address.toSLPAddress(
              excludedSlpAddresses[i],
            );
          }

          // Refactor
          // If it's an excluded address, your new circulating supply is lower
          // after scan all of them, get your new minSlpBalanceForDividend

          // 2 new vars
          // effectiveCirculatingSupply
          // effectiveMinSlpBalanceForDividend

          // Because some addresses are excluded, the circulating supply at only included addresses must be determined
          // call this var effectiveCirculatingSupply
          let effectiveCirculatingSupply = tokenInfo.circulatingSupply;
          result.forEach((v, k) => {
            if (excludedSlpAddresses.includes(k)) {
              effectiveCirculatingSupply -= v;
              console.log(
                `Because ${k} is excluded, circulating supply is reduced by ${v} from ${tokenInfo.circulatingSupply} to ${effectiveCirculatingSupply}`,
              );
            }
          });
          // use effectiveCirculatingSupply to determine effectiveMinSlpBalanceForDividend
          const dividendSats = new Big(parseFloat(dividendBch) * 1e8); // to be form data later
          const effectiveMinSlpBalanceForDividend =
            (this.dustLimit * effectiveCirculatingSupply) / dividendSats;
          this.setState({
            effectiveMinSlpBalanceForDividend,
            effectiveCirculatingSupply,
          });

          result.forEach((v, k) => {
            if (
              v.lt(effectiveMinSlpBalanceForDividend) ||
              excludedSlpAddresses.includes(k)
            ) {
              result.delete(k);
            }
          });
        } else {
          result.forEach((v, k) => {
            if (v.lt(minSlpBalanceForDividend)) {
              result.delete(k);
            }
          });
        }
        // Remove entries where token balance is too small for dividend

        const recipientCount = result.size;
        // Calculate many transactions will you need to pay this dividend, in batches of batchSize
        const txCount = Math.ceil(recipientCount / batchSize);

        let appStatus = (
          <FormattedHTMLMessage
            id="slp-dividend-calculator.appStatus.queryComplete"
            values={{
              recipientCount,
            }}
          />
        );

        if (txCount > 1) {
          // console.log(`More than 2500 outputs. Batching transactions...`);
          appStatus = (
            <FormattedHTMLMessage
              id="slp-dividend-calculator.appStatus.queryCompleteTxs"
              values={{
                recipientCount,
                txCount,
              }}
            />
          );
        }

        this.setState({
          appStatus,
        });

        // Determine the total supply of SLP tokens excluding holders with too small a balance to receive a dividend due to dust limit
        // You will divide by this to determine dividend payments -- to make sure you still pay the full dividend amount to all recipients
        const eligibleCirculatingSupply = Array.from(result.values()).reduce(
          (a, c) => a.plus(c),
          new Big(0),
        );
        // console.log(`eligibleCirculatingSupply: ${eligibleCirculatingSupply}`);
        // console.log(`token supplytoken supply: ${circulatingSupply}`);
        // const bch_amount = dividendSats / 1e8;
        const txOutputs = [];
        const txOutputsStrs = [];
        // console.time('forEach');
        // let totalSatsPaid = 0;
        result.forEach((v, k) => {
          const d = v.div(eligibleCirculatingSupply).mul(dividendBch);
          const bchAddress = SLP.Address.toCashAddress(k);

          // console.log(`${SLP.Address.toCashAddress(k)},`, d.toFixed(8));

          const txOutput = {};
          txOutput.address = bchAddress;
          txOutput.amount = +Math.floor(d * 1e8); // must be in sats for pay.bitcoin.com
          // totalSatsPaid += txOutput.amount;
          txOutputs.push(txOutput);

          const txOutputsStr = `${bchAddress.toString()},${d.toFixed(8)}`;
          txOutputsStrs.push(txOutputsStr);
        });
        // console.timeEnd('forEach');
        // console.log(`Total Sats Paid: ${totalSatsPaid}`);
        // console.log(`Total BCH paid: ${totalSatsPaid / 1e8}`);
        const outputTxStr = txOutputsStrs.join('\n');

        if (recipientCount === queryLimit) {
          const limitError = (
            <FormattedMessage
              id="slp-dividend-calculator.errors.limitError"
              values={{
                queryLimit,
              }}
            />
          );
          this.setState({
            limitError,
          });
        } else if (recipientCount === 0) {
          return this.setState({
            eligibleAddresses: recipientCount,
            limitError: formatMessage({
              id: 'slp-dividend-calculator.errors.noAddressesWithEnoughSlp',
            }),
            loading: false,
            txBuilt: true,
            appStatus: '',
          });
        } else {
          this.setState({
            outputTx: outputTxStr,
            eligibleAddresses: recipientCount,
            limitError: null,
          });
        }
        // console.log(txOutputs);

        // If your tx has more than 2500 outputs, you should split it into batches of no more than 2500 outputs each
        // This is to avoid a transaction larger than 100kb which most wallets would not send

        // How many transactions are needed?

        /*
        console.log(
          `Creating ${txCount} invoice(s) for ${txCount} transactions`,
        );
        */

        const invoicePromises = [];
        // console.log(`txCount: ${txCount}`);

        for (let i = 0; i < txCount; i += 1) {
          // Invoice memo
          let invoiceMemo = '';
          let txOutputsForTx = [];
          if (txCount === 1) {
            // Note: Choosing not to localize this string for now; unsure how some foreign characters could impact OP_RETURN fields
            invoiceMemo = `Paying ${dividendBch} BCH to holders of SLP Token ID ${tokenId} aka ${streetName} with a balance of at least ${minSlpBalanceForDividend} tokens (${txOutputs.length} total)`;
            txOutputsForTx = txOutputs;

            // TODO add op return output here
          } else {
            invoiceMemo = `Transaction ${i +
              // Note: Choosing not to localize this string for now; unsure how some foreign characters could impact OP_RETURN fields
              1} of ${txCount}: Paying ${dividendBch} total BCH to holders of SLP Token ID ${tokenId} aka ${streetName} with a balance of at least ${minSlpBalanceForDividend} tokens (${
              txOutputs.length
            } total)`;
            const outputIndexStart = i * batchSize;
            let outputIndexEnd = outputIndexStart + batchSize;
            if (i === txCount) {
              outputIndexEnd = recipientCount - outputIndexStart;
            }
            txOutputsForTx = txOutputs.slice(outputIndexStart, outputIndexEnd);
            // TODO add op return output here, incl (1 of 3) e.g. ... make sure your validation leaves room for this
            // const customOpReturnScript = formData.opReturn.value;
            // convert to hex

            /*
            let data = "BCHForEveryone";
            let buf = Buffer.from(data, 'ascii')
            let encoded = bitbox.Script.encodeNullDataOutput(buf);
            bitbox.Script.toASM(encoded);
            // OP_RETURN 424348466f7245766572796f6e65
            */
          }
          // Add an OP_RETURN output message to the transaction
          // Note this message will be added to each output

          if (includeOpReturnMsg) {
            const data = formData.opReturn.value;
            const buf = Buffer.from(data, 'ascii');
            const encoded = bitbox.Script.encodeNullDataOutput(buf); // this is what you add
            const asm = bitbox.Script.toASM(encoded);
            const prefix = '4469766964656e64'; // 'Dividend' in hex
            const prefixLength = 8; // number of characters in 'Dividends'
            // Add op prefix + length of message in hex + message as a hex encoded output
            // This is the format pay.bitcoin.com server will accept

            const nullDataOutput = `6a${(data.length + prefixLength).toString(
              16,
            )}${prefix}${asm.slice(10)}`;

            /* Verify nulldata
            Currently this says longer strings are valid, but ElectronCash knows they are not
            // TODO
            try {
              const bufferNullData = Buffer.from(nullDataOutput, 'hex');
              const validNullData = bitbox.Script.checkNullDataOutput(
                bufferNullData,
              );
              // console.log(`validNullData: ${validNullData}`);
            } catch (err) {
              // console.log(`Error in nulldata`);
              // console.log(err);
            } */

            // console.log(`Message length in decimal:`);
            // console.log(data.length + prefixLength);
            // console.log(`Message length in hex:`);
            // console.log(`${(data.length + prefixLength).toString(16)}`);
            // console.log(`msg for tx`);
            // console.log(nullDataOutput);

            // add this output to beginning of outputs for invoice
            const opRetOutput = { script: nullDataOutput, amount: 0 };
            txOutputsForTx.unshift(opRetOutput);
          }

          // promises go here
          invoicePromises[i] = fetch('https://pay.bitcoin.com/create_invoice', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              outputs: txOutputsForTx,
              memo: invoiceMemo,
            }),
          }).then(response => response.json());
        }

        // console.log(`invoicePromises:`);
        // console.log(invoicePromises);
        return this.setState(
          {
            appStatus,
          },
          this.parseInvoicePromises(invoicePromises),
        );
      },
      err => {
        console.log(`slpList.SlpdbQueries.GetAddressListFor(
          ${bestBlock},
          ${tokenId},
          ${slpdbUrl},
         )`);
        console.log(err);
        const slpdbError = (
          <FormattedMessage id="slp-dividend-calculator.errors.slpdbError" />
        );
        return this.setState({
          loading: false,
          slpdbError,
        });
      },
    );
  }

  setTabState(tab) {
    this.setState({
      tab,
    });
  }

  getBestBlockForSlpList(tokenInfo, minSlpBalanceForDividend, dividendBch) {
    const { formData, selectedOption } = this.state;
    bitbox.Blockchain.getBlockchainInfo().then(
      blockchainInfo => {
        let bestBlock = blockchainInfo.blocks;

        // Check if there is a custom blockheight
        if (selectedOption === 'customBlockheight') {
          const inputCustomBlockheight = parseInt(
            formData.customBlockheight.value,
            10,
          );
          // If custom blockheight has not yet been mined, break loop  and show error
          if (inputCustomBlockheight > bestBlock) {
            formData.customBlockheight.error = (
              <FormattedMessage id="slp-dividend-calculator.errors.blockNotYetMined" />
            );
            return this.setState({
              formData,
              loading: false,
            });
          }
          // If custom blockheight is before generation block for this token, break loop and show error
          if (inputCustomBlockheight < tokenInfo.blockCreated) {
            formData.customBlockheight.error = (
              <FormattedMessage
                id="slp-dividend-calculator.errors.tokenDidNotExist"
                values={{
                  blockCreated: tokenInfo.blockCreated,
                  inputCustomBlockheight,
                }}
              />
            );
            return this.setState({
              formData,
              loading: false,
            });
          }
          bestBlock = inputCustomBlockheight;
          // console.log(`Using custom blockheight of ${bestBlock}`);
        }
        if (selectedOption === 'mempoolState') {
          bestBlock = -1;
        }
        return this.setState(
          {
            blockUsedInScan: bestBlock,
          },
          this.getTokenHolderAddresses(
            bestBlock,
            tokenInfo,
            minSlpBalanceForDividend,
            dividendBch,
          ),
        );
      },
      err => {
        console.log(`Error in bitbox.Blockchain.getBlockchainInfo():`);
        console.log(err);
        return this.setState({
          loading: false,
        });
      },
    );
  }

  validateDividend = ({ name, value }) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { formData } = this.state;

    const field = formData[name];

    field.value = value;
    field.state = inputState.valid;
    field.error = null;
    if (!value) {
      field.state = inputState.invalid;
      field.error = formatMessage({
        id: 'slp-dividend-calculator.errors.dividendNum',
      });
    } else if (value < 0.00005) {
      field.state = inputState.invalid;
      field.error = formatMessage({
        id: 'slp-dividend-calculator.errors.dividendMin',
      });
    }

    return field;
  };

  validateBlockheight = ({ name, value }) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { formData } = this.state;

    const field = formData[name];

    field.value = value;
    field.state = inputState.valid;
    field.error = null;
    if (!value) {
      field.state = inputState.invalid;
      field.error = formatMessage({
        id: 'slp-dividend-calculator.errors.noBlockheight',
      });
    } else if (value < 543375) {
      field.state = inputState.invalid;
      field.error = formatMessage({
        id: 'slp-dividend-calculator.errors.blockheightTooLow',
      });
    }

    return field;
  };

  handleTokenIdError = ({ name, value }) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { formData } = this.state;

    const field = formData[name];

    field.value = value;
    field.state = inputState.invalid;
    field.error = formatMessage({
      id: 'slp-dividend-calculator.errors.invalidTokenId',
    });

    return field;
  };

  validateTokenId = ({ name, value }) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { formData } = this.state;

    const field = formData[name];

    field.value = value;
    field.state = inputState.valid;
    field.error = null;

    if (!value) {
      field.state = inputState.invalid;
      field.error = formatMessage({
        id: 'slp-dividend-calculator.errors.required',
      });
    } else if (value.length !== 64) {
      field.state = inputState.invalid;
      field.error = formatMessage({
        id: 'slp-dividend-calculator.errors.invalidTokenId',
      });
    }
    return field;
  };

  validateOpReturn = ({ name, value }) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { formData } = this.state;

    const field = formData[name];

    field.value = value;
    field.state = inputState.valid;
    field.error = null;

    // console.log(`Msg length: ${value.length}`)
    // console.log(`Msg length in hex: ${(value.length).toString(16)}`)

    if (value.length > 60) {
      // 50 is prob best for production, use 220 for testing
      field.state = inputState.invalid;
      field.error = formatMessage({
        id: 'slp-dividend-calculator.errors.opReturnTooLong',
      });
    }
    return field;
  };

  validateExcludedAddresses = ({ name, value }) => {
    // Valid input is comma separated list of valid BCH, SLP, or legacy addresses

    const {
      intl: { formatMessage },
    } = this.props;

    const { formData } = this.state;

    const field = formData[name];

    field.value = value;
    field.state = inputState.valid;
    field.error = null;

    // Remove spaces
    let excludedAddressesNoSpaces = value.replace(/\s+/g, '');
    // If the last character is a comma, remove it
    if (
      excludedAddressesNoSpaces.charAt(excludedAddressesNoSpaces.length - 1) ===
      ','
    ) {
      excludedAddressesNoSpaces = excludedAddressesNoSpaces.slice(0, -1);
    }
    // Parse for comma-separated list here
    const excludedAddresses = excludedAddressesNoSpaces.split(',');

    // validation would go here
    if (value.length && excludedAddresses.length) {
      for (let i = 0; i < excludedAddresses.length; i += 1) {
        try {
          bitbox.Address.toLegacyAddress(excludedAddresses[i]);
        } catch (err) {
          field.state = inputState.invalid;
          field.error = formatMessage({
            id: 'slp-dividend-calculator.errors.addressInvalid',
          });
          return field;
        }
        // if valid, convert it to SLP
        // no, do this when user clicks to submit, you don't need to do this here
        // excludedAddresses[i] = SLP.Address.toSLPAddress(excludedAddresses[i]);
      }
    }
    return field;
  };

  updateWindowDimensions() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  handleSlpdbChange(selectedSlpdb) {
    this.setState({ slpdb: selectedSlpdb });
  }

  // I don't understand this error msg as it applies to this function
  // eslint-disable-next-line class-methods-use-this
  copyUri(e) {
    document.getElementById(e.target.dataset.ref).select();
    document.execCommand('copy');
    // Show the the whole text area selected.
    document.getElementById(e.target.dataset.ref).focus();
  }

  // I don't understand this error msg as it applies to this function
  // eslint-disable-next-line class-methods-use-this
  copyToClipboard() {
    document.getElementById('txOutputArea').select();
    document.execCommand('copy');
    // Show the the whole text area selected.
    document.getElementById('txOutputArea').focus();
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState(
      {
        formStep: 'two',
        calcError: null,
        apiError: null, // handles case where user receives API error, then builds tx again, then no error
        slpdbError: null,
        appStatus: 'Collecting token info...',
      },
      window.scrollTo(0, 0),
    );
    const {
      // Keeping this here as I need to add localization strings for some msgs later TODO
      // eslint-disable-next-line no-unused-vars
      intl: { formatMessage },
    } = this.props;
    const { formData } = this.state;
    if (formData.slpTokenId.state !== 1) {
      return;
    }

    const tokenId = formData.slpTokenId.value;
    const dividendBch = formData.dividendBch.value;

    // Get the supply of this token
    SLP.Utils.list(tokenId).then(
      // TODO split the SLPDB query into a separate function that is returned by the result of SLP.Utils.List(tokenId)
      // eslint-disable-next-line consistent-return
      result => {
        console.log(result);
        const { blockCreated } = result;

        // get circulating supply from axios
        const q = {
          v: 3,
          q: {
            db: ['g'],
            aggregate: [
              {
                $match: {
                  'tokenDetails.tokenIdHex': tokenId,
                  'graphTxn.outputs': {
                    $elemMatch: {
                      status: 'UNSPENT',
                      slpAmount: { $gte: 0 },
                    },
                  },
                },
              },
              { $unwind: '$graphTxn.outputs' },
              {
                $match: {
                  'graphTxn.outputs.status': 'UNSPENT',
                  'graphTxn.outputs.slpAmount': { $gte: 0 },
                },
              },
              {
                $group: {
                  _id: null,
                  circulating_supply: {
                    $sum: '$graphTxn.outputs.slpAmount',
                  },
                },
              },
            ],
            limit: 100000,
          },
        };

        const data = Buffer.from(JSON.stringify(q)).toString('base64');

        const config = {
          method: 'GET',
          url: `https://slpdb.fountainhead.cash/q/${data}`,
        };

        axios(config).then(
          response => {
            // console.log(response);
            const circulatingSupply = parseFloat(
              response.data.g[0].circulating_supply,
            );

            // If circulatingSupply is null, throw an error
            if (circulatingSupply === null) {
              console.log(
                `Error in SLP.Utils.list(tokenId); circulatingSupply is null`,
              );

              // set state to show an error msg
              const calcError = (
                <FormattedMessage id="slp-dividend-calculator.errors.circulatingSupplyError" />
              );
              return this.setState({
                calcError,
                loading: false,
              });
            }
            let streetName = result.name;
            // if the token has no name, use the symbol
            if (streetName === '') {
              streetName = result.symbol;
            }
            if (streetName === '') {
              streetName = '(no name or symbol)';
            }

            if (typeof circulatingSupply === 'undefined') {
              // Can get here with a bad token ID, endpoint returns array of all tokens

              console.log(`Token ID not recognized`);
              const calcError = (
                <FormattedMessage id="slp-dividend-calculator.errors.invalidIdCalcError" />
              );

              return this.setState(
                {
                  calcError,
                  loading: false,
                },
                this.throwTokenIdError(),
              );
            }

            // console.log(`circulatingSupply: ${circulatingSupply}`)

            // log(`dustLimit: ${dustLimit}`)
            // log(`tokenSupply: ${tokenSupply}`)
            // log(`dividendSats: ${dividendSats}`)
            const dividendSats = new Big(parseFloat(dividendBch) * 1e8); // to be form data later

            // What amount X such that x/(quantity of outstanding tokens)* dividendSats < dustLimit ?
            const minSlpBalanceForDividend =
              (this.dustLimit * circulatingSupply) / dividendSats;

            // console.log(`For this level of dividend payments (${dividendBch} BCH) , the minimum token balance required to receive a dividend payment is ${minSlpBalanceForDividend} tokens.`)

            // Get all addresses with a token balance
            // return getTokenAddresses(minSlpBalanceForDividend, tokenSupply, utxos)
            const tokenInfo = {};
            tokenInfo.minSlpBalanceForDividend = minSlpBalanceForDividend;
            tokenInfo.circulatingSupply = circulatingSupply;
            tokenInfo.tokenId = tokenId;
            tokenInfo.streetName = streetName;
            tokenInfo.blockCreated = blockCreated;
            return this.setState(
              {
                tokenInfo,
                appStatus: (
                  <FormattedHTMLMessage
                    id="slp-dividend-calculator.appStatus.infoCollected"
                    values={{
                      streetName,
                      dividendBch,
                    }}
                  />
                ),
              },
              this.getBestBlockForSlpList(
                tokenInfo,
                minSlpBalanceForDividend,
                dividendBch,
              ),
            );
          },
          err => {
            console.log(
              `Error in fountainhead slpdb query for circulating supply`,
            );
            console.log(err);
            // set state to show an error msg
            const calcError = (
              <FormattedMessage id="slp-dividend-calculator.errors.restError" />
            );
            return this.setState({
              calcError,
              loading: false,
            });
          },
        );
        // end axios
      },
      err => {
        console.log(`Error in SLP.Utils.list(tokenId):`);
        console.log(err);
        // set state to show an error msg
        const calcError = (
          <FormattedMessage id="slp-dividend-calculator.errors.restError" />
        );
        return this.setState({
          calcError,
          loading: false,
        });
      },
    );
  }

  parseInvoicePromises(invoicePromises) {
    // console.log(`Entering parseInvoicePromises() with`);
    // console.log(invoicePromises);
    Promise.all(invoicePromises).then(
      res => {
        // console.log(`Result of Promise.all(invoicePromises):`)
        // console.log(res)
        // console.log(res[0].paymentId);

        const paymentIds = [];
        for (let i = 0; i < res.length; i += 1) {
          paymentIds.push(res[i].paymentId);
        }

        return this.setState(
          {
            paymentIds,
          },
          this.allInvoicesBuiltCheck(),
        );
      },
      err => {
        console.log(`Error in Promise.all(invoicePromises):`);
        console.log(err);
        const apiError = (
          <FormattedMessage id="slp-dividend-calculator.errors.apiError" />
        );
        this.setState({
          loading: false,
          txBuilt: true,
          apiError,
        });
      },
    );
  }

  allInvoicesBuiltCheck() {
    // Note: tried using this with the length of the PaymentIds state, but function (Even though callback) being called on un-updated paymentIds state
    // Confirm no undefined listings
    // console.log(`this.allInvoicesBuiltCheck()`)
    return this.setState({
      loading: false,
      txBuilt: true,
      appStatus: '',
    });
  }

  throwTokenIdError() {
    const { formData } = this.state;
    const { value } = formData.slpTokenId;
    const name = 'slpTokenId';
    this.setState({
      formData: {
        ...formData,
        [name]: this.handleTokenIdError({ name, value }),
      },
    });
  }

  handleRadioChange(e) {
    this.setState({
      selectedOption: e.target.value,
    });
  }

  handleDividendChange(e) {
    const { value, name } = e.target;
    const { formData } = this.state;

    this.setState({
      formData: { ...formData, [name]: this.validateDividend({ name, value }) },
    });
  }

  handleBlockheightChange(e) {
    const { value, name } = e.target;
    const { formData } = this.state;

    this.setState({
      formData: {
        ...formData,
        [name]: this.validateBlockheight({ name, value }),
      },
    });
  }

  handleTokenIdChange(e) {
    const { value, name } = e.target;
    const { formData } = this.state;

    this.setState({
      formData: { ...formData, [name]: this.validateTokenId({ name, value }) },
    });
  }

  handleOpReturnChange(e) {
    const { value, name } = e.target;
    const { formData } = this.state;
    this.setState({
      formData: { ...formData, [name]: this.validateOpReturn({ name, value }) },
    });
  }

  handleExcludedAddressesChange(e) {
    const { value, name } = e.target;
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        [name]: this.validateExcludedAddresses({ name, value }),
      },
    });
  }

  handleButtonClick() {
    // If form is valid
    const { formData } = this.state;
    if (formData.slpTokenId.state === 1 && formData.dividendBch.state === 1) {
      this.setState({
        loading: true,
        txBuilt: false,
        paymentIds: [],
      });
    }
  }

  reset() {
    this.setState(
      {
        formData: merge({}, this.initialFormData),
        txBuilt: false,
        selectedOption: 'lastConfirmedBlock',
        invoicesExpired: '',
        calcError: null,
        limitError: null,
        apiError: null,
        slpdbError: null,
        formStep: 'one',
        toggleOutput: false,
        outputTx: [],
        excludeAddresses: false,
      },
      window.scrollTo(0, 0),
    );
  }

  handleBackToStart() {
    // Same as reset but don't remove form data
    this.setState(
      {
        txBuilt: false,
        selectedOption: 'lastConfirmedBlock',
        invoicesExpired: '',
        calcError: null,
        limitError: null,
        apiError: null,
        slpdbError: null,
        formStep: 'one',
        toggleOutput: false,
        outputTx: [],
      },
      window.scrollTo(0, 0),
    );
  }

  expireInvoices(err) {
    let errMsg = '';
    if (err.message.includes('denied transaction')) {
      errMsg = ``; // Canceled by user, no msg needed
    } else {
      errMsg = ``; // formerly `BIP70 Invoice is expired.` but this is now handled by badger-components-react
    }
    this.setState({
      invoicesExpired: errMsg,
    });
  }

  toggleAdvancedOptions() {
    const { toggleAdvanced } = this.state;
    this.setState({ toggleAdvanced: !toggleAdvanced });
  }

  toggleDividendOutput() {
    const { toggleOutput } = this.state;
    this.setState({ toggleOutput: !toggleOutput });
  }

  handleAddrExclude() {
    const { excludeAddresses } = this.state;
    this.setState({ excludeAddresses: !excludeAddresses });
  }

  handleOpReturnMsgInclude() {
    const { includeOpReturnMsg } = this.state;
    this.setState({ includeOpReturnMsg: !includeOpReturnMsg });
  }

  render() {
    const {
      // I'm not sure how locale is used in the monorepo, keeping it here to match other templates
      // eslint-disable-next-line no-unused-vars
      locale,
      intl: { formatMessage },
    } = this.props;

    const {
      formData,
      tokenInfo,
      effectiveCirculatingSupply,
      effectiveMinSlpBalanceForDividend,
      eligibleAddresses,
      outputTx,
      txBuilt,
      loading,
      paymentIds,
      tab,
      limitError,
      apiError,
      calcError,
      appStatus,
      blockUsedInScan,
      selectedOption,
      invoicesExpired,
      slpdb,
      toggleAdvanced,
      excludeAddresses,
      includeOpReturnMsg,
      toggleOutput,
      formStep,
      slpdbError,
      windowWidth,
    } = this.state;
    const tabs = [
      {
        label: formatMessage({
          id: 'slp-dividend-calculator.tabs.invoice',
        }),
        action: () => this.setTabState(false),
      },
      {
        label: formatMessage({
          id: 'slp-dividend-calculator.tabs.electron',
        }),
        action: () => this.setTabState(true),
      },
    ];

    const progressMsg = <p>{appStatus}</p>;

    const errorDuringCalcMsg = <p>{calcError}</p>;

    const invoiceQrs = [];
    const invoiceTotal = paymentIds.length;
    let invoiceNumber = 1;

    // let sortedPaymentIdArr = []
    // let paymentIdArr = this.state.paymentIds

    // Sort so you present invoices in order
    if (paymentIds.length !== 0 && txBuilt) {
      // Iterate through invoices (you will have more than one if you have more than 2500 outputs)
      paymentIds.forEach(paymentId => {
        const invoiceUrl = `https://pay.bitcoin.com/i/${paymentId}`;
        // const uriStr = `bitcoincash:?r=https://pay.bitcoin.com/i/${paymentId}`;
        const titleStr = (
          <FormattedMessage
            id="slp-dividend-calculator.output.invoiceLabels"
            values={{
              invoiceNumber,
              invoiceTotal,
            }}
          />
        );
        invoiceQrs.push(
          <InvoiceTable key={invoiceUrl}>
            <p>{titleStr}</p>
            <Invoice
              sizeQR={windowWidth > 700 ? 250 : windowWidth / 3.2}
              copyUri
              linkAvailable={false}
              paymentRequestUrl={invoiceUrl}
              isRepeatable={false}
            />
            {/*
            <tbody>
              <tr>
                <BadgerTd colSpan="2">
                  <BadgerButton
                    text={titleStr}
                    paymentRequestUrl={invoiceUrl}
                    isRepeatable={false}
                    failFn={this.expireInvoices}
                  />
                </BadgerTd>
              </tr>
              <tr>
                <TruncatedTD>

                  <UriTxtArea rows="4" id={paymentId} value={uriStr} readOnly />
                </TruncatedTD>
                <CopyUriTd>
                  <CtrlC data-ref={paymentId} onClick={this.copyUri}></CtrlC>
                </CopyUriTd>
              </tr>
              <tr>
                <td colSpan="2">
                  <InputError show>{invoicesExpired}</InputError>
                </td>
              </tr>
            </tbody> */}
          </InvoiceTable>,
        );
        invoiceNumber += 1;
      });
    }
    let batchingAlert = '';
    if (paymentIds.length > 1) {
      batchingAlert = (
        <FormattedMessage id="slp-dividend-calculator.output.batchingAlert" />
      );
    }

    return (
      <React.Fragment>
        {formStep === 'one' && (
          <React.Fragment>
            <CustomContentBlock hero noPadding>
              <Form id="submit-form" onSubmit={this.handleSubmit}>
                <InputWrapper show>
                  <InputLabel>
                    <FormattedMessage id="slp-dividend-calculator.labels.slpTokenId" />{' '}
                    <Red>*</Red>
                  </InputLabel>
                  <Input
                    id="inputSlpTokenId"
                    name="slpTokenId"
                    type="string"
                    value={formData.slpTokenId.value}
                    onChange={this.handleTokenIdChange}
                    placeholder={formatMessage({
                      id: 'slp-dividend-calculator.input.slpTokenId',
                    })}
                    required
                  />
                </InputWrapper>
                <InputError show>{formData.slpTokenId.error}</InputError>
                <InputWrapper show>
                  <InputLabel>
                    <FormattedMessage id="slp-dividend-calculator.labels.dividendBch" />{' '}
                    <Red>*</Red>
                  </InputLabel>
                  <Input
                    id="inputDividendBch"
                    name="dividendBch"
                    type="number"
                    min="0.00005"
                    step="any"
                    value={formData.dividendBch.value}
                    onChange={this.handleDividendChange}
                    placeholder={formatMessage({
                      id: 'slp-dividend-calculator.input.dividendBch',
                    })}
                    required
                  />
                </InputWrapper>
                <InputError show>{formData.dividendBch.error}</InputError>
                <InputWrapper show>
                  <Checkbox onChange={this.handleAddrExclude}></Checkbox>
                  <CheckLabel>Exclude addresses?</CheckLabel>
                </InputWrapper>
                <InputWrapper show={excludeAddresses}>
                  <InputLabel>
                    <FormattedMessage id="slp-dividend-calculator.labels.excludeAddresses" />{' '}
                  </InputLabel>
                  <Input
                    id="excludeAddr"
                    name="excludedAddresses"
                    type="string"
                    value={formData.excludedAddresses.value}
                    onChange={this.handleExcludedAddressesChange}
                    placeholder={formatMessage({
                      id: 'slp-dividend-calculator.input.excludeAddresses',
                    })}
                  />
                </InputWrapper>
                <InputError show={excludeAddresses}>
                  {formData.excludedAddresses.error}
                </InputError>
                <InputWrapper show>
                  <CustomLink onClick={this.toggleAdvancedOptions}>
                    {toggleAdvanced === true ? (
                      <FormattedMessage id="slp-dividend-calculator.labels.hideAdvanced" />
                    ) : (
                      <FormattedMessage id="slp-dividend-calculator.labels.showAdvanced" />
                    )}
                  </CustomLink>
                </InputWrapper>

                {toggleAdvanced && (
                  <React.Fragment>
                    <InputWrapper show>
                      <Checkbox
                        onChange={this.handleOpReturnMsgInclude}
                      ></Checkbox>
                      <CheckLabel>Include OP_RETURN Message?</CheckLabel>
                    </InputWrapper>
                    <InputWrapper show={includeOpReturnMsg}>
                      <InputLabel>
                        <FormattedMessage id="slp-dividend-calculator.labels.opReturn" />{' '}
                      </InputLabel>
                      <Input
                        id="inputOpReturn"
                        name="opReturn"
                        type="string"
                        value={formData.opReturn.value}
                        onChange={this.handleOpReturnChange}
                        placeholder={formatMessage({
                          id: 'slp-dividend-calculator.input.opReturn',
                        })}
                      />
                    </InputWrapper>
                    <InputError show>{formData.opReturn.error}</InputError>

                    <InputWrapper show>
                      <Label>
                        <FormattedMessage
                          tagName="div"
                          id="slp-dividend-calculator.radio.lastConfirmed"
                        />
                        <input
                          type="radio"
                          value="lastConfirmedBlock"
                          checked={selectedOption === 'lastConfirmedBlock'}
                          onChange={this.handleRadioChange}
                        />
                        <span />
                      </Label>
                      <Label>
                        <FormattedMessage
                          tagName="div"
                          id="slp-dividend-calculator.radio.mempool"
                          values={{
                            test: selectedOption,
                          }}
                        />
                        <input
                          type="radio"
                          value="mempoolState"
                          checked={selectedOption === 'mempoolState'}
                          onChange={this.handleRadioChange}
                        />
                        <span />
                      </Label>
                      <Label>
                        <FormattedMessage
                          tagName="div"
                          id="slp-dividend-calculator.radio.customBlockheight"
                          values={{
                            test: selectedOption,
                          }}
                        />
                        <input
                          type="radio"
                          value="customBlockheight"
                          checked={selectedOption === 'customBlockheight'}
                          onChange={this.handleRadioChange}
                        />
                        <span />
                      </Label>
                    </InputWrapper>
                    <InputWrapper show={selectedOption === 'customBlockheight'}>
                      <InputLabel>
                        <FormattedMessage id="slp-dividend-calculator.labels.customBlockheight" />{' '}
                        <Red>*</Red>
                      </InputLabel>
                      <Input
                        disabled={selectedOption !== 'customBlockheight'}
                        id="inputCustomBlockheight"
                        name="customBlockheight"
                        type="number"
                        min="543375"
                        step="1"
                        value={formData.customBlockheight.value}
                        onChange={this.handleBlockheightChange}
                        placeholder={formatMessage({
                          id: 'slp-dividend-calculator.input.customBlockheight',
                        })}
                        required
                      />
                    </InputWrapper>
                    <InputError show={selectedOption === 'customBlockheight'}>
                      {formData.customBlockheight.error}
                    </InputError>
                    <InputWrapper show>
                      <InputLabel>
                        <FormattedMessage id="slp-dividend-calculator.labels.slpdbSelect" />{' '}
                      </InputLabel>
                      <CustomSelect
                        options={[
                          {
                            value: 'https://slpdb.bitcoin.com',
                            label: 'slpdb.bitcoin.com',
                          },
                          {
                            value: 'https://slpdb.fountainhead.cash',
                            label: 'slpdb.fountainhead.cash',
                          },
                        ]}
                        value={slpdb}
                        onChange={this.handleSlpdbChange}
                      />
                    </InputWrapper>
                  </React.Fragment>
                )}
              </Form>
            </CustomContentBlock>
            <CustomContentBlock hero>
              <Buttons>
                <Button
                  type="submit"
                  form="submit-form"
                  primary
                  onClick={this.handleButtonClick}
                >
                  {loading ? (
                    <FormattedMessage id="slp-dividend-calculator.buttons.loading" />
                  ) : (
                    <FormattedMessage id="slp-dividend-calculator.buttons.submit" />
                  )}
                </Button>
                <Button type="button" onClick={this.reset}>
                  <FormattedMessage id="slp-dividend-calculator.buttons.reset" />
                </Button>
              </Buttons>
            </CustomContentBlock>
          </React.Fragment>
        )}
        {formStep === 'two' && (
          <React.Fragment>
            <ProgressContainer show={loading}>
              <CustomContentBlock hero noPadding>
                {progressMsg}
              </CustomContentBlock>
            </ProgressContainer>
            <ErrorContainer show={calcError !== null}>
              <CustomContentBlock hero noPadding>
                {errorDuringCalcMsg}
              </CustomContentBlock>
            </ErrorContainer>
          </React.Fragment>
        )}

        {txBuilt && (
          <Buttons>
            <Button type="button" onClick={this.toggleDividendOutput}>
              {toggleOutput === true ? (
                <FormattedMessage id="slp-dividend-calculator.buttons.hideDetails" />
              ) : (
                <FormattedMessage id="slp-dividend-calculator.buttons.showDetails" />
              )}
            </Button>
            <Button dark type="button" onClick={this.reset}>
              <FormattedMessage id="slp-dividend-calculator.buttons.reset" />
            </Button>
          </Buttons>
        )}
        <DividendDetails show={toggleOutput}>
          <CustomContentBlock hero noPadding>
            <H3>
              <FormattedMessage id="slp-dividend-calculator.output.title" />
            </H3>

            <OutputWrapper>
              <b>
                <FormattedMessage id="slp-dividend-calculator.output.blockUsedInScan" />
              </b>{' '}
              {blockUsedInScan} <br />
              <b>
                <FormattedMessage id="slp-dividend-calculator.output.dividendPayment" />
              </b>{' '}
              {formData.dividendBch.value} BCH <br />
              <b>
                <FormattedMessage id="slp-dividend-calculator.output.tokenInfo" />
              </b>{' '}
              {tokenInfo.tokenId} aka &quot;{tokenInfo.streetName}&quot; <br />
              {effectiveCirculatingSupply !== 0 ? (
                <>
                  <b>
                    <FormattedMessage id="slp-dividend-calculator.output.effectiveCirculatingSupply" />
                  </b>{' '}
                  {effectiveCirculatingSupply.toLocaleString()} <br />
                  <b>
                    <FormattedMessage id="slp-dividend-calculator.output.minSlp" />
                  </b>{' '}
                  {effectiveMinSlpBalanceForDividend.toFixed(8)} <br />
                </>
              ) : (
                <>
                  <b>
                    <FormattedMessage id="slp-dividend-calculator.output.circulatingSupply" />
                  </b>{' '}
                  {tokenInfo.circulatingSupply.toLocaleString()} <br />
                  <b>
                    <FormattedMessage id="slp-dividend-calculator.output.minSlp" />
                  </b>{' '}
                  {tokenInfo.minSlpBalanceForDividend.toFixed(8)} <br />
                </>
              )}
              <Small>
                <FormattedMessage
                  id="slp-dividend-calculator.output.minSlpNote"
                  values={{
                    minTokenBalance: tokenInfo.minSlpBalanceForDividend.toLocaleString(),
                  }}
                />
              </Small>
              <p>
                <FormattedMessage
                  id="slp-dividend-calculator.output.eligibleMsg"
                  values={{ eligibleAddresses }}
                />
                {batchingAlert}
              </p>
            </OutputWrapper>
          </CustomContentBlock>
        </DividendDetails>
        <InputError show>{limitError}</InputError>
        <InputError show>{apiError}</InputError>
        <InputError show={slpdbError !== null && !loading}>
          {slpdbError}
        </InputError>
        {slpdbError !== null && !loading && (
          <RetryButtons>
            <Button primary onClick={this.handleBackToStart}>
              <FormattedMessage id="slp-dividend-calculator.buttons.tryAgain" />
            </Button>
            <Button type="button" onClick={this.reset}>
              <FormattedMessage id="slp-dividend-calculator.buttons.reset" />
            </Button>
          </RetryButtons>
        )}

        <Feedback show={txBuilt}>
          <Content>
            <Tabs selectedTab={tab === false ? 0 : 1} tabs={tabs} />
            {tab === true ? (
              <OutputWrapper>
                <center>
                  <H3>
                    {document.queryCommandSupported('copy') && (
                      <Button primary onClick={this.copyToClipboard}>
                        <FormattedMessage id="slp-dividend-calculator.output.copyBtn" />
                      </Button>
                    )}
                  </H3>
                </center>
                <InputLabel>
                  <FormattedMessage id="slp-dividend-calculator.output.outputLabels" />
                </InputLabel>
                <TextArea
                  id="txOutputArea"
                  value={outputTx}
                  readOnly
                ></TextArea>
              </OutputWrapper>
            ) : (
              <div>
                <Instructions>
                  <FormattedMessage
                    id="slp-dividend-calculator.output.scanInstructions"
                    values={{
                      walletLink: (
                        <a
                          href="https://wallet.bitcoin.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Bitcoin.com wallet
                        </a>
                      ),
                    }}
                  />
                </Instructions>

                <InvoiceWrapper>{invoiceQrs}</InvoiceWrapper>
              </div>
            )}
          </Content>
        </Feedback>
      </React.Fragment>
    );
  }
}

SlpDivWidget.propTypes = {
  locale: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

export default injectIntl(SlpDivWidget);
// Comment
