import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

const SEO = ({ intl }) => (
  <Helmet
    title={intl.formatMessage({ id: 'slp-dividend-calculator.meta.title' })}
  >
    <meta
      name="description"
      content={intl.formatMessage({
        id: 'slp-dividend-calculator.meta.description',
      })}
    />
    <meta
      property="og:title"
      content={intl.formatMessage({ id: 'slp-dividend-calculator.meta.title' })}
    />
    <meta
      property="og:description"
      content={intl.formatMessage({
        id: 'slp-dividend-calculator.meta.description',
      })}
    />
    <meta
      property="og:image"
      content={`https://www.bitcoin.com${intl.formatMessage({
        id: 'slp-dividend-calculator.meta.image',
      })}`}
    />
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="twitter:site"
      content={`@${intl.formatMessage({
        id: 'slp-dividend-calculator.meta.twitter',
      })}`}
    />
    <meta
      name="twitter:title"
      content={intl.formatMessage({ id: 'slp-dividend-calculator.meta.title' })}
    />
    <meta
      name="twitter:description"
      content={intl.formatMessage({
        id: 'slp-dividend-calculator.meta.description',
      })}
    />
    <meta
      name="twitter:image"
      content={`https://www.bitcoin.com${intl.formatMessage({
        id: 'slp-dividend-calculator.meta.image',
      })}`}
    />
    <link
      rel="canonical"
      href="https://tools.bitcoin.com/slp-dividend-calculator/"
    />
  </Helmet>
);

SEO.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

export default injectIntl(SEO);
