import React from 'react';
import PropTypes from 'prop-types';
import Nav from '@bitcoin-portal/navbar';
import Footer from '@bitcoin-portal/footer';
import {
  ContentBlock,
  H1,
  Paragraph,
} from '@bitcoin-portal/bitcoincom-storybook';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
  Wrapper,
  ContentWrapper,
  Buttons,
  OptionButton,
  Reset,
  BlockedCollapse,
  ShowFaq,
} from './styled';
import SEO from './SEO';
import SlpDivWidget from './SlpDivWidget';
import Airdropper from './Airdropper';

class SlpDividendCalculator extends React.Component {
  constructor(props) {
    super(props);

    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.toggleFaq = this.toggleFaq.bind(this);

    this.state = {
      option: null,
      showFaq: false,
    };
  }

  handleButtonClick(e) {
    let option = e.target.offsetParent.id;
    if (option === '') {
      option = e.target.id;
    }
    this.setState({
      option,
      showFaq: false,
    });
  }

  handleReset() {
    this.setState({
      option: null,
    });
  }

  toggleFaq() {
    const { showFaq } = this.state;
    this.setState({
      showFaq: !showFaq,
    });
  }

  render() {
    const { locale } = this.props;
    const { option, showFaq } = this.state;
    const showFaqBtn = !showFaq && option === null;
    return (
      <div>
        <SEO />
        <Wrapper>
          <Nav />
          <ContentBlock hero>
            <H1>
              <FormattedMessage id="slp-dividend-calculator.header.title" />
            </H1>
            <Paragraph center>
              <FormattedMessage id="slp-dividend-calculator.header.text" />
            </Paragraph>
            <Buttons solo={option !== null}>
              <OptionButton
                primary
                style={
                  option !== 'airdrop'
                    ? { transition: `width ${0.25}s, height ${1}s` }
                    : {
                        transition: `width ${0.25}s, height ${1}s`,
                        width: `${0}px`,
                        height: `${0}px`,
                      }
                }
                id="dividends"
                onClick={this.handleButtonClick}
              >
                <FormattedMessage id="slp-dividend-calculator.buttons.dividendSelect" />
              </OptionButton>
              <OptionButton
                style={
                  option !== 'dividends'
                    ? { transition: `width ${0.25}s, height ${1}s ` }
                    : {
                        transition: `width ${0.25}s, height ${1}s `,
                        width: `${0}px`,
                        height: `${0}px`,
                      }
                }
                type="button"
                id="airdrop"
                onClick={this.handleButtonClick}
              >
                <FormattedMessage id="slp-dividend-calculator.buttons.airdropSelect" />
              </OptionButton>
              <Reset
                show={option !== null}
                style={
                  option !== null
                    ? {
                        width: `${36}px`,
                      }
                    : {
                        width: `${0}px`,
                      }
                }
                onClick={this.handleReset}
              >
                X
              </Reset>
            </Buttons>
            {showFaqBtn && (
              <ShowFaq id="faq" onClick={this.toggleFaq}>
                <FormattedMessage id="slp-dividend-calculator.buttons.showFaq" />
              </ShowFaq>
            )}

            {option === 'dividends' && (
              <ContentWrapper>
                <SlpDivWidget locale={locale} />
              </ContentWrapper>
            )}
            {option === 'airdrop' && (
              <ContentWrapper>
                <Airdropper locale={locale} />
              </ContentWrapper>
            )}
            {showFaq && (
              <ContentBlock>
                <BlockedCollapse
                  list={[
                    {
                      title: 'Why dividends?',
                      text: (
                        <FormattedMessage id="slp-dividend-calculator.collapseInfo.why" />
                      ),
                    },
                    {
                      title: 'Why SLP?',
                      text: (
                        <FormattedMessage id="slp-dividend-calculator.collapseInfo.whySlp" />
                      ),
                    },
                    {
                      title: 'How does it work?',
                      text: (
                        <FormattedMessage id="slp-dividend-calculator.collapseInfo.how" />
                      ),
                    },
                  ]}
                />
                <ShowFaq id="null" onClick={this.toggleFaq}>
                  <FormattedMessage id="slp-dividend-calculator.buttons.hideFaq" />
                </ShowFaq>
              </ContentBlock>
            )}
          </ContentBlock>
          <Footer />
        </Wrapper>
      </div>
    );
  }
}

SlpDividendCalculator.propTypes = {
  locale: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

export default injectIntl(SlpDividendCalculator);
