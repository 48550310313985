import styled, { css } from 'styled-components';
import {
  theme,
  media,
  Paragraph,
  ContentBlock,
  OL,
  Link,
  Select,
} from '@bitcoin-portal/bitcoincom-storybook';
import { transparentize } from 'polished';

export const InputWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding-bottom: ${theme.spacing.unit * 3}px;
  padding-top: ${theme.spacing.unit}px;
  display: ${({ show = false }) => (show === true ? 'block' : 'none')};

  & > input {
    width: 100%;
    max-width: unset;
    margin: 0;
  }

  @media screen and (min-width: ${theme.breakpoints.md}px) {
    width: 100%;
    padding-top: ${theme.spacing.unit * 2}px;
  }
`;

export const Form = styled.form`
  text-align: left;
  @media screen and (min-width: ${theme.breakpoints.md}px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const CustomContentBlock = styled(ContentBlock)`
  padding-left: 0;
  overflow-wrap: break-word;
  padding-right: 0;
  transition: opacity ${theme.transitions.duration.default}ms
    ${theme.transitions.easing.easeInOut};

  ${({ disabled = false }) => {
    if (disabled === false) return '';
    return css`
      opacity: 0.3;
      pointer-events: none;
    `;
  }};

  ${({ noPadding = false }) => {
    if (noPadding === false) return '';
    return css`
      padding: 0;

      & > div > div {
        padding: 0;
      }
    `;
  }};
`;

export const UriWrapper = styled.div`
  width: 100%;
  margin-bottom: 12px;
`;
export const UriTxtArea = styled.textarea`
  resize: none;
  width: 100%;
`;
export const BadgerTd = styled.td`
  & > div {
    padding-left: 0px;
  }
  ${media.md`

    & > div {
      padding-left: 8px;
    }
  `};
`;
export const CopyUriTd = styled.td`
  padding-left: 8px;
`;

export const CtrlC = styled.button`
  border: none;
  box-shadow: none;
  background: url('/images/uploads/copy.svg') no-repeat center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-size: 16px;
`;

export const Buttons = styled.div`
  & > div:first-child {
    margin-bottom: ${theme.spacing.unit}px;
  }

  ${media.sm`
    margin: -${theme.spacing.unit}px;

    & > div {
      margin: ${theme.spacing.unit}px;
    }
  `};
`;

export const RetryButtons = styled.div`
  margin-top: 12px !important;
  & > div:first-child {
    margin-bottom: ${theme.spacing.unit}px;
  }

  ${media.sm`
    margin: -${theme.spacing.unit}px;

    & > div {
      margin: ${theme.spacing.unit}px;
    }
  `};
`;

export const Red = styled.span`
  color: #f44336;
`;

export const InputError = styled.div`
  display: ${({ show = false }) => (show === true ? 'block' : 'none')};
  color: #f44336;
  font-size: 0.8rem;
`;

export const CheckLabel = styled.span`
  padding-left: 32px;
`;

export const TextArea = styled.textarea`
  @media screen and (max-width: 350px) {
    max-width: 190px;
  }
  border-radius: ${theme.border.radius.default}px;
  padding: 0 ${theme.spacing.unit * 3}px;
  height: 250px;
  background-color: ${theme.palette.background.default};
  border: ${theme.border.solid.default} ${theme.palette.border.default};
  color: ${theme.palette.text.primary};
  margin: ${theme.spacing.unit}px 0;
  width: 98%;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.14);
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  ${({ padding = false }) =>
    padding &&
    css`
      padding-right: ${theme.spacing.unit * 7}px;
    `};
  &::placeholder {
    color: ${theme.palette.text.placeholder};
  }
  &:focus,
  &:focus-within {
    border-color: ${theme.palette.primary.main};
  }
  ${({ disabled = false }) => {
    if (disabled === false) return '';
    return css`
      background-color: ${theme.palette.background.headerLight};
      border-color: ${theme.palette.text.disabled};
      color: ${theme.palette.text.disabled};
      pointer-events: none;
      &:focus,
      &:focus-within {
        border-color: ${theme.palette.text.disabled};
      }
    `;
  }};
`;

export const Feedback = styled.div`
  width: 100%;
  & > div {
    width: 100%;
  }
  display: ${({ show = false }) => (show === true ? 'flex' : 'none')};
  box-sizing: border-box;
  background-color: ${transparentize(0.3, theme.palette.background.default)};
`;

export const DividendDetails = styled.div`
  margin-top: 24px;
  width: 100%;
  display: ${({ show = false }) => (show === true ? 'flex' : 'none')};
  box-sizing: border-box;
  background-color: ${transparentize(0.3, theme.palette.background.default)};
`;
export const ProgressContainer = styled.div`
  width: 100%;
  display: ${({ show = false }) => (show === true ? 'flex' : 'none')};
  box-sizing: border-box;
  background-color: ${transparentize(0.3, theme.palette.background.default)};
`;

export const ErrorContainer = styled.div`
  color: red;
  width: 100%;
  display: ${({ show = false }) => (show === true ? 'flex' : 'none')};
  box-sizing: border-box;
  background-color: ${transparentize(0.3, theme.palette.background.default)};
`;

export const OutputWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding-bottom: ${theme.spacing.unit * 3}px;
  padding-top: ${theme.spacing.unit}px;

  & > input {
    width: 100%;
    max-width: unset;
    margin: 0;
  }

  @media screen and (min-width: ${theme.breakpoints.md}px) {
    width: 100%;
    padding-top: ${theme.spacing.unit * 2}px;
  }
`;

export const ImageWrapper = styled.div`
  width: 320px;
  margin: auto;
  text-align: left;
  box-sizing: border-box;
  position: relative;
  padding-bottom: ${theme.spacing.unit * 3}px;
  padding-top: ${theme.spacing.unit}px;

  & > input {
    width: 320px;
    max-width: unset;
    margin: 0;
  }

  @media screen and (min-width: ${theme.breakpoints.md}px) {
    width: 320px;
    padding-top: ${theme.spacing.unit * 2}px;
  }
`;

export const Small = styled(Paragraph)`
  font-size: 0.8em;
  max-width: 700px;
`;
export const SmallCenter = styled(Paragraph)`
  text-align: center;
  font-size: 0.8em;
  max-width: 600px;
  margin-bottom: 0px;
  padding-bottom: 0px;
`;
export const Instructions = styled(Paragraph)`
  text-align: center;
  font-size: 0.8em;
  max-width: 600px;
  margin-bottom: 24px;
  padding-bottom: 0px;
`;

export const Content = styled.div`
  max-width: 600px;
  margin: auto;
  padding-top: ${theme.spacing.unit * 2}px;

  ${media.sm`
    padding-top: ${theme.spacing.unit * 4}px;
  `};

  & > div:first-child {
    margin-bottom: ${theme.spacing.unit * 2}px;
  }
`;

export const Label = styled.label`
  width: 100%;
  padding-bottom: 12px;
  display: block;
  position: relative;
  padding-left: ${theme.spacing.unit * 5}px;
  cursor: pointer;
  user-select: none;
  font-weight: 600;
  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  & > span {
    position: absolute;
    top: 0;
    left: 0;
    height: ${theme.spacing.unit * 3}px;
    width: ${theme.spacing.unit * 3}px;
    background-color: ${({ disabled = false }) =>
      disabled
        ? theme.palette.background.headerLight
        : theme.palette.background.default};
    border-radius: 50%;
    border: ${theme.border.solid.default} ${theme.palette.border.default};
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.14);
    box-sizing: border-box;
  }
  /* When the radio button is checked, add a blue background */
  & > input:checked ~ span {
    border-color: ${({ disabled = false }) =>
      disabled ? theme.palette.border.default : theme.palette.primary.main};
  }
  /* Create the indicator (the dot/circle - hidden when not checked) */
  & > span:after {
    content: '';
    position: absolute;
    display: none;
  }
  /* Show the indicator (dot/circle) when checked */
  & > input:checked ~ span:after {
    display: block;
  }
  /* Style the indicator (dot/circle) */
  & > span:after {
    top: 4px;
    left: 4px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    ${({ disabled = false }) => {
      if (disabled === true)
        return css`
          background-color: ${theme.palette.text.disabled};
        `;
      return css`
        background: linear-gradient(
          -180deg,
          ${theme.palette.primary.radioDark} 0%,
          ${theme.palette.primary.main} 100%
        );
      `;
    }};
    box-shadow: 0 0.5px 0.5px 0 rgba(0, 0, 0, 0.3);
  }
`;

export const CustomOL = styled(OL)`
  max-width: 800px;
  font-size: 24px;
  padding-left: 12px;

  ${media.sm`
    padding-left: ${theme.spacing.unit * 3}px;
  `};
`;
export const InvoiceWrapper = styled.div``;
export const InvoiceTable = styled.table`
  margin: 0 auto;
`;
export const TruncatedTD = styled.td`
  font-size: 12px;
`;
export const AirdropAlert = styled(Paragraph)`
  color: rgb(10, 193, 142);
  padding-bottom: 0px;
`;
export const AirdropAlertLink = styled(Link)`
  text-decoration: underline;
`;
export const CustomSelect = styled(Select)`
  z-index: 1000;
  max-width: 100%;
  & > div {
    max-width: 100%;
  }
`;
export const CustomLink = styled(Link)`
  font-size: 0.9rem;
  cursor: pointer;
  display: ${({ show = false }) => (show === true ? 'block' : 'none')};
`;

export const AirdropTabAlert = styled(Paragraph)`
  text-align: center;
  font-size: 0.9rem;
  color: rgb(10, 193, 142);
`;
