import styled, { css } from 'styled-components';
import {
  theme,
  media,
  ContentBlock,
  H3,
  OL,
  Button,
  Collapse,
} from '@bitcoin-portal/bitcoincom-storybook';

export const SlpH3 = styled(H3)`
  font-weight: normal;
`;

export const Wrapper = styled.div`
  width: 100%;
  position: relative;

  & > input {
    width: 100%;
    margin-top: ${theme.spacing.unit * 2}px;
  }

  ${media.sm`
    & > input {
      max-width: 450px;
      margin-top: ${theme.spacing.unit * 4}px;
    }
  `};
`;

export const Address = styled.div`
  margin-bottom: ${theme.spacing.unit * 2}px;
  word-break: break-all;
`;

export const Content = styled.div`
  max-width: 600px;
  padding-top: ${theme.spacing.unit * 2}px;

  ${media.sm`
    padding-top: ${theme.spacing.unit * 4}px;
  `};

  & > div:first-child {
    margin-bottom: ${theme.spacing.unit * 2}px;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  margin-bottom: ${theme.spacing.unit * 8}px;

  & > section {
    padding: 0;
  }

  & > section > div {
    min-height: unset;
  }

  & > section > div > div {
    padding: 0;
    margin: 0;
  }

  & > section > div > div > input {
    width: 100%;
  }

  ${media.sm`
    margin-bottom: ${theme.spacing.unit * 16}px;
  `};
`;

export const CustomContentBlock = styled(ContentBlock)`
  padding-left: 0;
  padding-right: 0;
`;

export const CustomOL = styled(OL)`
  max-width: 800px;
  font-size: 24px;
`;

export const Buttons = styled.div`
  position: relative;
  & > div:first-child {
    margin-bottom: ${theme.spacing.unit}px;
  }

  ${media.sm`
    margin: -${theme.spacing.unit}px;

    & > div {
      margin: ${theme.spacing.unit}px;
    }
  `};

  ${({ solo = false }) => {
    if (solo === false) return '';
    return css`
      margin: auto !important;
    `;
  }};
`;

export const OptionButton = styled(Button)`
  padding: 6px;
`;

export const Reset = styled(Button)`
  max-width: 36px;
  visibility: ${({ show = false }) => (show === true ? 'visible' : 'hidden')};
  opacity: ${({ show = false }) => (show === true ? '1' : '0')};
  transition: ${({ show = false }) =>
    show === true ? 'visibility 1s, opacity 1s, width 1s;' : ''};
  transition-delay: ${({ show = false }) => (show === true ? '0.5s' : '')};
`;

export const ShowFaq = styled.button`
  cursor: pointer;
  border: none;
  color: #0ac18e;
  background-color: transparent;
  & :hover {
    text-decoration: underline;
    background-color: transparent;
  }
`;

export const BlockedCollapse = styled(Collapse)`
  padding-top: 0px;
  padding-bottom: 12px;
`;
